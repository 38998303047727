import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import ScrollTo from "stimulus-scroll-to"
import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"
import Remote from "stimulus-remote-rails"

const application = Application.start()
const context = require.context('@controllers', true, /\.js$/)

application.load(definitionsFromContext(context))

application.register("scroll-to", ScrollTo)
application.register("scroll-reveal", ScrollReveal)
application.register("reveal", Reveal)
application.register("remote", Remote)
