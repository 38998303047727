import { Controller } from 'stimulus'
import paper from 'paper'
import { gsap, Power1 } from "gsap/all"

export default class extends Controller {
  static values = {
    height: Number,
    width: Number,
    interval: Number
  }

  connect () {
    // points en pourcentage par rapport aux bords du canvas
    this.points = [
      { x: 14, y: 15 },
      { x: 40, y: 3 },
      { x: 82, y: 18 },
      { x: 94, y: 68 },
      { x: 77, y: 90 },
      { x: 58, y: 95 },
      { x: 35, y: 90 },
      { x: 14, y: 66 },
      { x: 5, y: 47 }]

    this.heightCanvas = this.heightValue || '490'
    this.widthCanvas = this.widthValue || '549'
    this.animationInterval = this.intervalValue || '20'

    this.element.width = this.widthCanvas
    this.element.height = this.heightCanvas

    this.paper = paper.setup(this.element)

    this.initializeCanvas()
  }

  disconnect () {
    this.paper = null
    this.path = null
    this.tl1 = null
  }

  initializeCanvas () {
    this.path = new paper.Path({
      strokeColor: '#006264',
      strokeWidth: 3
    })

    this.points.forEach(point => {
      const newPoint = new paper.Point(this.pointX(point.x), this.pointY(point.y))
      this.path.add(newPoint)
    })

    this.path.closed = true
    this.path.smooth({ type: 'continuous' })

    this.path.segments.forEach(segment => {
      this.animatePath(segment.point, segment.index * 0.25)
    })
  }

  animatePath (point, segmentDelay) {
    // eslint-disable-next-line
    this.tl1 = new gsap.timeline({
      onComplete: function () {
        this.restart()
      }
    })

    this.tl1.to(point, 1.5, { y: "+=" + this.animationInterval, ease: Power1.easeInOut, delay: segmentDelay })
    this.tl1.to(point, 1.5, { y: "-=" + this.animationInterval, ease: Power1.easeInOut, delay: segmentDelay })
  }

  pointX (pourcent) {
    return pourcent * this.widthCanvas / 100
  }

  pointY (pourcent) {
    return pourcent * this.heightCanvas / 100
  }
}
