/* eslint no-console:0 */

// Polyfill =====================================================================
import "intersection-observer"

// MODULES =====================================================================
import Rails from 'rails-ujs'
// Uncomment next line if you need Vue
// import "@appPages/vue"

import "stylesheets/tailwind-base.sass"
import "stylesheets/application.sass"
import "stylesheets/tailwind-components.sass"
import ImageObjectFit from "@utils/image-object-fit"
import ConstantHeight from "@utils/constant-height"
import FlashMessage from "@utils/flash-messages"
import executeRecaptcha from "@utils/grecaptcha"

// Stimulus
import "@controllers"

if (process.env.NODE_ENV === "development") {
  import("stylesheets/development.sass")
}

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage
window.Rails = Rails
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new ConstantHeight()
})
